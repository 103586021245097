.oqtBG {
    height: 100vh;
    position: relative;
    overflow: hidden;

    background: url('../../Assets/bg-1.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

/* .pageEff {
    height: 100vh;
    position: absolute;

    mix-blend-mode: multiply;
} */

.logoContainer {
    height: 100vh;

    display: flex;
    justify-content: left;
    align-items: center;

    padding: 0 0 5% 4%;
}
.logoContainer>img {
    width: calc(25 * (1vw + 1vh - 1vmin));
    width: 25vmax;
    height: auto;
}

@media only screen and (max-width: 800px) {
    .oqtBG {
        height: 100vh;

        background-size: cover;
    }

    .logoContainer {
        height: 100;

        display: flex;
        justify-content: right;
        align-items: flex-start;
    
        padding: 22% 6% 0 0;
    }
    .logoContainer>img {
        width: calc(34 * (1vw + 1vh - 1vmin));
        width: 34vmax;
        height: auto;
    }
}