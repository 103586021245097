.teBG {
    height: 100vh;
    position: relative;
    overflow: hidden;

    background: url('../../Assets/bg-3.png');
    background-position: center;
    background-repeat: no-repeat;
}
.teContent {
    height: 100vh;

    display: flex;
    justify-content: left;
    align-items: center;
}
.teImgHolder {
    width: 25%;

    transform: translate(-10%);
    -ms-transform: translate(-10%);
    -moz-transform: translate(-10%);
    -webkit-transform: translate(-10%);

    animation: heartbeat 0.7s infinite;
    -webkit-animation: heartbeat 0.7s infinite;
    -moz-animation: heartbeat 0.7s infinite;
}
.coracao {
    height: calc(42 * (1vw + 1vh - 1vmin));
    height: 42vmax;
}

.teTextHolder {
    width: 75%;

    display: block;

    padding-right: 6%;

    color: var(--s-color);
}
.teTitleHolder {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-bottom: 2%;
}
.teTitle {
    height: calc(9.5 * (1vw + 1vh - 1vmin));
    height: 9.5vmax;
}
.teBody {
    padding: 0 20% 0 20%;

    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;

    font-size: calc(1.2 * (1vw + 1vh - 1vmin));
    font-size: 1.2vmax;

    text-align: justify;
    text-align-last: center;
}

@media only screen and (max-width: 800px) {
    .teContent {
        display: block;
    }
    .teContent>div {
        margin-left: auto;
        margin-right: auto;
    }
    .teImgHolder {
        width: 100%;
    }
    .coracao {
        height: calc(62 * (1vw + 1vh - 1vmin));
        height: 62vmax;
    }

    .teTextHolder {
        width: 100%;
        position: absolute;

        transform: translateY(-70%);

        padding: 0 8% 0 8%;
    }
    .teTitleHolder {
        padding-bottom: 5%;
    }
    .teTitle {
        height: calc(13 * (1vw + 1vh - 1vmin));
        height: 13vmax;
    }
    .teBody {
        padding: 0;

        font-size: calc(2.2 * (1vw + 1vh - 1vmin));
        font-size: 2.2vmax;
    }
}

@keyframes heartbeat {
    from {
        transform: scale(1) translate(-20%, -30%);
        -ms-transform: scale(1) translate(-20%, -30%);
        -moz-transform: scale(1) translate(-20%, -30%);
        -webkit-transform: scale(1) translate(-20%, -30%);
    } to {
        transform: scale(1.1) translate(-20%, -30%);
        -ms-transform: scale(1.1) translate(-20%, -30%);
        -moz-transform: scale(1.1) translate(-20%, -30%);
        -webkit-transform: scale(1.1) translate(-20%, -30%);
    }
}