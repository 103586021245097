.saoBG {
    height: 100vh;
    position: relative;
    overflow: hidden;
    
    background: url('../../Assets/bg-2.png');
    background-position: center;
    background-repeat: no-repeat;
}
.saoContent {
    height: 100vh;

    display: flex;
    justify-content: left;
    align-items: center;
}
.saoContent>div {
    width: 50%;
}
.saoImgHolder {
    transform: translate(-150%);
    -ms-transform: translate(-150%);
    -moz-transform: translate(-150%);
    -webkit-transform: translate(-150%);
}
.asa {
    height: calc(25 * (1vw + 1vh - 1vmin));
    height: 25vmax;
}

.saoTextHolder {
    display: block;

    transform: scale(0);
    -ms-transform: scale(0);
    -moz-transform: scale(0);
    -webkit-transform: scale(0);
    
    padding: 0 8% 0 5%;

    color: var(--p-color);
}
.saoTextHolder>p {
    padding: 1% 0 1% 0;
}
.saoTitle {
    font-family: 'Stretch Pro';
    font-size: calc(2.6 * (1vw + 1vh - 1vmin));
    font-size: 2.6vmax;
}
.saoBody {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    font-size: calc(1.2 * (1vw + 1vh - 1vmin));
    font-size: 1.2vmax;
    text-align: justify;
}
.saoFinal {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 900;
    font-size: calc(1.2 * (1vw + 1vh - 1vmin));
    font-size: 1.2vmax;
}

@media only screen and (max-width: 800px) {
    .saoContent {
        display: block;
    }
    .saoContent>div {
        width: 100%;

        margin-left: auto;
        margin-right: auto;
    }
    .saoImgHolder {
        padding-top: 20%;
        transform: translate(-150%);
    }
    .asa {
        height: calc(30 * (1vw + 1vh - 1vmin));
        height: 30vmax;
    }

    .saoTextHolder {
        width: 70%;
        padding: 5% 8% 0 8%;
    }
    .saoTextHolder>p {
        padding: 2% 0 2% 0;
    }
    .saoTitle {
        font-family: 'Stretch Pro';
        font-size: calc(4.5 * (1vw + 1vh - 1vmin));
        font-size: 4.5vmax;
    }
    .saoBody {
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 600;
        font-size: calc(2.2 * (1vw + 1vh - 1vmin));
        font-size: 2.2vmax;
        text-align: justify;
    }
    .saoFinal {
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 900;
        font-size: calc(2.2 * (1vw + 1vh - 1vmin));
        font-size: 2.2vmax;
    }
}

@keyframes wing {
    from {
        opacity: 0;

        transform: translate(-150%);
        -ms-transform: translate(-150%);
        -moz-transform: translate(-150%);
        -webkit-transform: translate(-150%);
    } to {
        opacity: 1;

        transform: translate(-10%);
        -ms-transform: translate(-10%);
        -moz-transform: translate(-10%);
        -webkit-transform: translate(-10%);
    }
}

@keyframes textAnim {
    0% {
        opacity: 0;
        
        transform: scale(0);
        -ms-transform: scale(0);
        -moz-transform: scale(0);
        -webkit-transform: scale(0);
    }
    80% {
        opacity: 1;
        
        transform: scale(1.1);
        -ms-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
    }
    100% {
        opacity: 1;
        
        transform: scale(1);
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
    }
}