.aBG {
    height: 100vh;
    position: relative;
    overflow: hidden;

    background: url('../../Assets/bg-4.png');
    background-position: center;
    background-repeat: no-repeat;
}
.aContent {
    height: 100vh;

    display: flex;
    justify-content: right;
    align-items: center;
}
.aContent>div {
    padding: 0 4% 0 4%;
}

.aImgHolder {
    transform: scale(0);
    -ms-transform: scale(0);
    -moz-transform: scale(0);
    -webkit-transform: scale(0);
}
.cerebro {
    height: calc(32 * (1vw + 1vh - 1vmin));
    height: 32vmax;
}

.aTextHolder {
    display: block;

    width: 50%;
    padding-right: 12%;

    color: var(--p-color);
}
.aTextHolder>div {
    padding: 1% 0 1% 0;
}
.aTitle {
    font-family: 'Stretch Pro';
    font-size: calc(2.4 * (1vw + 1vh - 1vmin));
    font-size: 2.4vmax;

    opacity: 0;

    transform: translateX(20%);
    -ms-transform: translateX(20%);
    -moz-transform: translateX(20%);
    -webkit-transform: translateX(20%);
}
.aBody {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    font-size: calc(1.2 * (1vw + 1vh - 1vmin));
    font-size: 1.2vmax;
}
.aBody>div {
    padding: 0.5% 0 0.5% 0;

    opacity: 0;

    transform: translateX(20%);
    -ms-transform: translateX(20%);
    -moz-transform: translateX(20%);
    -webkit-transform: translateX(20%);
}

@media only screen and (max-width: 800px) {
    .aContent {
        display: block;
    }
    .aContent>div {
        width: 80%;
        margin-left: auto;
        margin-right: auto;

        padding: 7% 2% 0 2%;
    }

    .aImgHolder {
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .cerebro {
        height: 30vmax;
        margin-left: auto;
        margin-right: auto;
    }

    .aTextHolder>div {
        padding: 0 0 4% 0;
    }
    .aTitle {
        font-size: calc(3.5 * (1vw + 1vh - 1vmin));
        font-size: 3.5vmax;
    }
    .aBody {
        font-size: calc(1.7 * (1vw + 1vh - 1vmin));
        font-size: 1.7vmax;
    }
    .aBody>div {
        padding: 0.4% 0 0.4% 0;
    }
}

@keyframes brain {
    0% { 
        transform: scale(0);
        -ms-transform: scale(0);
        -moz-transform: scale(0);
        -webkit-transform: scale(0);
    }
    80% {
        transform: scale(1.05);
        -ms-transform: scale(1.05);
        -moz-transform: scale(1.05);
        -webkit-transform: scale(1.05);
    }
    100% {
        transform: scale(1);
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
    }
}

@keyframes aItemsAnim {
    from {
        opacity: 0;

        transform: translateX(20%);
        -ms-transform: translateX(20%);
        -moz-transform: translateX(20%);
        -webkit-transform: translateX(20%);
    } to {
        opacity: 1;

        transform: translateX(0);
        -ms-transform: translateX(0);
        -moz-transform: translateX(0);
        -webkit-transform: translateX(0);
    }
}