.qjdoBG {
    height: 100vh;
    position: relative;
    overflow: hidden;

    background: url('../../Assets/bg-5.png');
    background-position: center;
    background-repeat: no-repeat;

    text-align: center;
}
.qjdoContent {
    padding-top: 6%;
}

.qjdoTitle {
    font-family: 'Stretch Pro';
    font-size: calc(2.1 * (1vw + 1vh - 1vmin));
    font-size: 2.1vmax;

    padding: 1.5% 0 1.5% 0;
}

.qjdoImgs {
    list-style: none;

    width: 60%;
    margin-left: auto;
    margin-right: auto;
}
.qjdoImgs>li {
    padding: 0.8% 1.5% 0.8% 1.5%;

    display: inline-block;
    vertical-align: middle;
}
.qjdoImgs>li>img {
    width: calc(6.8 * (1vw + 1vh - 1vmin));
    width: 6.8vmax;
}

@media only screen and (max-width: 800px) {
    .qjdoContent {
        padding-top: 30%;
    }
    
    .qjdoTitle {
        font-family: 'Stretch Pro';
        font-size: calc(4 * (1vw + 1vh - 1vmin));
        font-size: 4vmax;

        padding: 8% 0 8% 0;
    }
    
    .qjdoImgs {
        list-style: none;
    
        width: 95%;
    }
    .qjdoImgs>li {
        padding: 2% 2.5% 2% 2.5%;
    }
    .qjdoImgs>li>img {
        width: calc(8 * (1vw + 1vh - 1vmin));
        width: 8vmax;
    }
}