.navbarHolder {
    position: fixed;
    overflow-x: hidden;

    background: var(--s-color);
    color: var(--p-color);

    width: 100%;
    z-index: 1;

    transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -webkit-transition: background-color 0.5s ease;
}
.navbar {
    height: 100%;

    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 10%;

    font-family: 'Maven Pro', sans-serif;
    font-weight: 800;
}
.navbar>a {
    height: 100%;
    padding: 2.5% 2% 2.5% 2%;

    display: flex;
    justify-content: right;
    align-items: center;

    text-decoration: none;
    color: inherit;

    /* border-bottom: 3px solid transparent; */
}
.navbar>a:hover {
    /* border-bottom: 3px solid var(--t-color); */
    color: var(--t-color);

    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.navbar>a:hover.yellow {
    color: var(--s-color);
}

.burgerHolder {
    background: var(--s-color);
    display: none;

    transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -webkit-transition: background-color 0.5s ease;
}
.burger {
    cursor: pointer;
}

.burger>div {
    width: 25px;
    height: 3px;
    background: var(--p-color);
    margin: 5px;

    transition: transform 0.3s ease;
    -moz-transition: transform 0.3s ease;
    -webkit-transition: transform 0.3s ease;
}

@media only screen and (max-width: 800px) {
    .navbar {
        display: none;

        flex-direction: column;
        align-items: flex-end;
        float: right;

        transform: translateX(180%);
        -ms-transform: translateX(180%);
        -moz-transform: translateX(180%);
        -webkit-transform: translateX(180%);

        padding: 8% 0 0 0;
    }
    .navbar.open {
        transform: translateX(0%);
        -ms-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -webkit-transform: translateX(0%);
    }
    .navbar>a {
        text-align: right;
        padding: 20% 0 20% 0;
        opacity: 0;
    }

    .burgerHolder {
        display: flex;
        float: right;
        padding: 5%;
    }

    .b-l1.open {
        transform: rotate(-45deg) translate(-5px, 6px);
    }
    .b-l2.open {
        opacity: 0;
    }
    .b-l3.open {
        transform: rotate(45deg) translate(-5px, -6px);
    }
}

@keyframes navLinkFade {
    from {
        opacity: 0;

        transform: translateX(50px);
        -ms-transform: translateX(50px);
        -moz-transform: translateX(50px);
        -webkit-transform: translateX(50px);
    } to {
        opacity: 1;

        transform: translateX(0px);
        -ms-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -webkit-transform: translateX(0%);
    }
}