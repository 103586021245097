@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;900&display=swap');

@font-face {
  font-family: 'Stretch Pro';
  src: url('./Assets/Fonts/StretchPro.otf');
}

:root {
  --p-color: white;
  --s-color: black;
  --t-color: #ffba00;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}